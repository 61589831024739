import styled from "styled-components";

const A = styled.a`
    font-size: ${p => p.fontSize};
    color: ${p => p.color};
    font-weight: ${p => p.fontWeight};
    font-family: ${p => p.fontFamily};
    text-decoration: none;
`

export default A;