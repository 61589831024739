import React from 'react'
import styled from 'styled-components'
import Text from '../../styled-components/Text'
import CardSlider from '../shared/CardSlider';
import {Carousel} from '3d-react-carousal';
import Image from '../../styled-components/Image';
import alab from '../../assets/images/alab.png'
import useCourses from '../../hooks/useCourses';
import { useEffect } from 'react';


const Courses = (props) => {

    const { handleCourses, teacher } = props

    const { courses } = useCourses(teacher?.courses || false)

    useEffect(() => {
        courses && handleCourses(courses.map(course => course.id))
    }, [courses])
    

    const callback = function(index){
        // console.log("callback",index);
    }

    if (!courses) {
        return <div/>
    }

    const cards = courses.map(item => <CardSlider image={item.image} name={item.name} description={item.description}/>)

    return (
        <CoursesContainer id="courses" teacher={teacher}>
            <Text id="sub-title" fontSize="24px" fontWeight="900" fontFamily="Lato">APRENDE · COMPARTE · IMPACTA</Text>
            <Text id="title" fontSize="64px" fontWeight="700">Realiza {courses.length - 1 > 1 && 'hasta'} {courses.length - 1} {courses.length - 1 === 1 ? 'curso' : 'cursos'} de impacto</Text>
            <CenterText>
                <Text id="description" fontFamily="Lato" fontSize="16px" fontWeight="400">
                    ADEN te brinda acceso a {courses.length - 1 === 1 ? 'este' : 'todos'} , tú eliges cuál quieres cursar.
                    Al completar cada uno obtendrás una certificación de Acrópolis LAB
                </Text>
            </CenterText>
            <Slider>
                <Carousel slides={cards} autoplay={true} interval={7000} onSlideChange={callback}/>
            </Slider>
            <Certification>
                <Text fontFamily="Lato" fontSize="18px" fontWeight="700">CERTIFICACIÓN</Text>
                <Image src={alab} alt="AcropolisLab"/>
            </Certification>
        </CoursesContainer>
    )
}

export default Courses

const CoursesContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    position: relative;
    padding: 2rem 0;
    z-index: 9999;
    ${p => (p.teacher.hash !== 'ca' || "ac") && `
        margin-top: calc(100vh + 2rem);
    `}
    @media (max-width: 768px){
        text-align: center;
        padding: 0;
        #title{
            font-size: 24px;
        }
        #sub-title{
            font-size: 14px;
        }
        #description{
            font-size: 14px;
        }
    }
`

const Slider = styled.div`
    width: 100%;
    margin: 2rem 0;
`

const Certification = styled.div`
    margin: 18px 0;
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: center;
    @media (max-width: 768px){
        flex-direction: column;
    }
`

const CenterText = styled.div`
    width: 50%;
    text-align: center;
    @media (max-width: 768px){
        width: 100%;
    }
`