import axios from "axios";

const SISAPI_URL = process.env.REACT_APP_SISAPI;
const REPOAPI_URL = process.env.REACT_APP_REPOAPI;
const IC_ID = 73;

const CONFIG = {
    headers: {
        Authorization: `${process.env.REACT_APP_APIKEY}`,
    },
};

export const registerUser = async (body) => {
    try {
        const { data } = await axios.post(
            SISAPI_URL + "/v1/acropolis/ondemand/register/incompany/" + IC_ID,
            body,
            CONFIG
        );
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const sendMail = async (body) => {
    try {
        const { data } = await axios.post(
            SISAPI_URL + "/v1/acropolis/email/send",
            body,
            CONFIG
        );
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCountries = async () => {
    try {
        const { data } = await axios.get(
            SISAPI_URL + "/v1/acropolis/miscs/countries"
        );
        return data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCompanies = async () => {
    try {
        const { data } = await axios.get(
            REPOAPI_URL + "/v1/repo_aden/miscs/empresas"
        );
        return data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};
