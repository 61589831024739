import { Button } from "@mui/material";
import { Formik, Form as FormFormik, Field, ErrorMessage } from "formik";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { registerUser, sendMail } from "../../api/sis-api";
import { registerSchema } from "../../schemas/auth";
import A from "../../styled-components/A";
import Text from "../../styled-components/Text";
import DefaultField from "../shared/DefaultField";
import { useSnackbar } from "react-simple-snackbar";
import useUtils from "../../hooks/useUtils";
import DefaultComplete from "../shared/DefaultComplete";
import useSearchEmailTemplate from "../../hooks/useSearchEmailTemplate";
import { emailTemplateUser } from "../../utils/emailTemplate";

const ADEN_URL = process.env.REACT_APP_ADEN;
const ACROPOLIS_URL = process.env.REACT_APP_ACROPOLIS;

const Form = (props) => {
    const { courses, teacher } = props;
    // hooks
    const { countries, companies } = useUtils();
    const { emailRegister } = useSearchEmailTemplate(teacher.hash);

    const initialValues = {
        name: "",
        lastName: "",
        country: "",
        company: "",
        email: "",
        password: "",
        confirmPass: "",
        term: true,
    };

    const [loading, setLoading] = useState(false);
    const [openSnackbar] = useSnackbar();

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const body = {
                firstname: values.name,
                lastname: values.lastName,
                country_id: values.country.id,
                email: values.email,
                password: values.password,
                courses: courses,
                company_id: values.company.empresa_id,
                company_name: values.company.empresa_nombre,
                medium_id: 615,
                utm_campaign: 'Proyecto100',
                source_id: 465,
                landing_url: window.location.href,
                // Agregar id de JSON
                group_id: teacher.group_id,
            };
            const bodyUser = {
                from_email: "adencursos@aden.org",
                to_emails: [values.email],
                subject:
                    "Ingresa a la plataforma educativa de AcrópolisLab! | ADEN te acompaña",
                html_content: emailRegister,
            };
            const bodyIternalEmail = {
                from_email: "tecnologia@aden.org",
                to_emails: "aden.proyecto100@aden.org",
                subject: "Nuevo registro de usuario desde IMPACTO",
                html_content: emailTemplateUser(
                    values,
                    teacher.group_id,
                    courses,
                    teacher.hash
                ),
            };
            await registerUser(body);
            await sendMail(bodyUser);
            await sendMail(bodyIternalEmail);
            setLoading(false);
            openSnackbar("Se creó correctamente el usuario");
            setTimeout(() => {
                window.open(ACROPOLIS_URL, "_self");
            }, 1000);
        } catch (error) {
            console.error(error);
            openSnackbar("Ocurrió un problema al cargar la solicitud");
        } finally {
            setLoading(false);
        }
    };

    return (
        <FormContainer id="form">
            <Text fontFamily="Lato" fontSize="24px" fontWeight="900">
                ENCIENDE TU CHISPA
            </Text>
            <Text id="title-form" fontSize="48px" fontWeight="700">
                Regístrate y comienza a cursar
            </Text>
            <FormWrapper>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={registerSchema}
                >
                    {({ setFieldValue, values }) => (
                        <FormF>
                            {/* <Alert>
                    <p>
                        ¡Muchas gracias por el interés en los cursos de impacto!
                        Ha finalizado el tiempo de inscripción.
                    </p>
                    <span>Para más consultas: aculasso@aden.org</span>
                </Alert> */}
                            <Col>
                                <DefaultField
                                    name="name"
                                    type="text"
                                    label="Nombre"
                                    variant="outlined"
                                    required
                                />
                                <DefaultField
                                    name="lastName"
                                    type="text"
                                    label="Apellido"
                                    variant="outlined"
                                    required
                                />
                            </Col>
                            <Col>
                                <DefaultComplete
                                    name="country"
                                    label="País"
                                    options={countries}
                                    freeSolo={false}
                                    setFieldValue={setFieldValue}
                                    variant="outlined"
                                />
                                <DefaultComplete
                                    objectMod={{
                                        empresa_nombre: "value",
                                        empresa_id: false,
                                    }}
                                    name="company"
                                    label="Empresa"
                                    // POR AHORA ARRAY SIN OPCIONES HASTA QUE MODIFIQUEN EL ENDPOINT
                                    options={[]}
                                    freeSolo={true}
                                    setFieldValue={setFieldValue}
                                    variant="outlined"
                                />
                            </Col>
                            {/** EMAIL */}
                            <DefaultField
                                name="email"
                                type="text"
                                label="Correo electrónico"
                                variant="outlined"
                                onChange={(e) =>
                                    setFieldValue(
                                        "email",
                                        e.target.value.toLowerCase()
                                    )
                                }
                                values={values.email}
                                required
                            />
                            <DefaultField
                                name="password"
                                type="password"
                                label="Contraseña"
                                variant="outlined"
                                required
                            />
                            <DefaultField
                                name="confirmPass"
                                type="password"
                                label="Repetir contraseña"
                                variant="outlined"
                                required
                            />
                            <CenterMessage>
                                <Field required type="checkbox" name="term" />
                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                    fontFamily="Lato"
                                >
                                    Acepto los{" "}
                                    <A
                                        target={"_blank"}
                                        fontSize="16px"
                                        fontWeight="400"
                                        fontFamily="Lato"
                                        color="#F24036"
                                        href={ADEN_URL}
                                    >
                                        Términos y Condiciones
                                    </A>
                                </Text>
                            </CenterMessage>
                            <Button
                                // disabled={true}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {!loading ? "Registrarme" : "Cargando"}
                            </Button>
                            <CenterMessage>
                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                    fontFamily="Lato"
                                >
                                    ¿Ya creaste tu cuenta?{" "}
                                </Text>
                                <A
                                    target={"_blank"}
                                    fontSize="16px"
                                    fontWeight="900"
                                    fontFamily="Lato"
                                    color="#fff"
                                    href={ACROPOLIS_URL}
                                >
                                    Iniciar sesión
                                </A>
                            </CenterMessage>
                        </FormF>
                    )}
                </Formik>
            </FormWrapper>
        </FormContainer>
    );
};

export default Form;

const FormContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    position: relative;
    padding: 2rem 0;
    /* z-index: 9999; */
    @media (max-width: 768px) {
        #title-form {
            text-align: center;
            font-size: 24px;
        }
    }
`;

const FormWrapper = styled.div`
    width: 497px;
    height: auto;
    background-color: #222222;
    border-radius: 20px;
    @media (max-width: 768px) {
        width: auto;
    }
`;

const FormF = styled(FormFormik)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 50px auto;
    width: 90%;
    #submit {
        margin: 0 auto;
        width: 150px;
        margin-top: 20px;
    }
`;

const Col = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
`;

const CenterMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
`;

const Alert = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.3rem;
    border: solid 1px #ffbc00;
    border-radius: 20px;
    p {
        font-size: 1rem;
    }

    span {
        font-size: 0.8rem;
    }
`;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    font-family: Lato;
    color: #d24335;
`;
