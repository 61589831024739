import React from 'react'
import "../../assets/sass/candle.scss"

export const Candle = () => {

    const handleClick = () => {
        // const flame = document.querySelector("#candle-flame")
        // console.log('flame', flame)
    }

    return (
        <div class="candle">
            <div id="candle-flame" class="flame" onClick={handleClick}>
                <div class="shadows"></div>
                <div class="top"></div>
                <div class="middle"></div>
                <div class="bottom"></div>
            </div>
            <div class="wick"></div>
            <div class="wax"></div>
        </div>
    )
}
