import { Button, Dialog, IconButton } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Text from '../../styled-components/Text'
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import { useState } from 'react';

const Share = (props) => {

    const { open = false, onClose } = props

    const [copy, setCopy] = useState(false)

    const ORIGIN = window.location.href
    const FACEBOOK = `http://www.facebook.com/sharer.php?u=${ORIGIN}/&t=Un colega te invitó a expandir tu conocimiento`
    const TWITTER = `http://www.twitter.com/share?url=${ORIGIN}`
    const LINKEDIN = `https://www.linkedin.com/sharing/share-offsite/?url=${ORIGIN}`
    const WHATSAPP = `https://wa.me/whatsappphonenumber/?text=¡Tengo una invitación especial para ti!\n
    Accede a estos cursos con certificación Acrópolis Lab by ADEN, para impactar juntos en nuestra organización. \n
    Tienes tiempo de inscribirte sin costo hasta el 30 de septiembre.\n ${ORIGIN}`
    const EMAIL = `mailto:example@gmail.com?body=¡Tengo una invitación especial para ti!\n
    Accede a estos cursos con certificación Acrópolis Lab by ADEN, para impactar juntos en nuestra organización. \n
    Tienes tiempo de inscribirte sin costo hasta el 30 de septiembre.\n ${ORIGIN}&subject=Aden te acompaña`

    const copyClickBoard = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopy(true);
        setTimeout(() => {
            setCopy(false)
        }, 2000);
    }

    return (
        <DialogShare open={open} onClose={onClose}>
            <Content>
                <Close>
                    <IconButton onClick={onClose}><CloseIcon /></IconButton>
                </Close>
                <Text fontSize="24px" fontWeight="900" fontFamily="Lato">¡Expande la chispa!</Text>
                <Text fontSize="20px" fontWeight="400" fontFamily="Lato">Invita a tus colegas y colaboradores</Text>
                <Social>
                    <SocialIcon onClick={() => window.open(FACEBOOK)} bg="#3b589a"><FacebookIcon /></SocialIcon>
                    <SocialIcon onClick={() => window.open(TWITTER)} bg="#00acee"><TwitterIcon /></SocialIcon>
                    <SocialIcon onClick={() => window.open(LINKEDIN)} bg="#0080b0"><LinkedInIcon /></SocialIcon>
                    <SocialIcon onClick={() => window.open(WHATSAPP)} bg="#26d367"><WhatsAppIcon /></SocialIcon>
                    <SocialIcon onClick={() => window.open(EMAIL)} bg="#db4437"><EmailIcon /></SocialIcon>
                </Social>
                <Text color="#a8a8a8" fontSize="16px" fontWeight="400" fontFamily="Lato">o copia el link</Text>
                <ShareLink>
                    <Link color="#a8a8a8" fontSize="16px" fontWeight="400" fontFamily="Lato">{ORIGIN}</Link>
                    <Button disabled={copy} onClick={copyClickBoard} color="primary" variant="contained">{!copy ? 'Copiar' : 'Copiado'}</Button>
                </ShareLink>
            </Content>
        </DialogShare>
    )
}

export default Share

const DialogShare = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 20px;
        background-color: #222;
        min-width: 432px;
        height: 380px;
        padding: 24px;
        color: #fff;
        @media (max-width: 768px){
            min-width: auto;
        }
    }
    /* position: relative; */
`

const Social = styled.div`
    width: 100%;
    height: 60px;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    gap: 24px;
    align-items: center;
    @media (max-width: 768px){
        gap: 8px;
    }
`

const ShareLink = styled.div`
    width: calc(100% - 36px);
    height: 50px;
    background-color: #333;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 18px;
    padding-right: 5px;
`

const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    position: relative;
`

const Close = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`

const SocialIcon = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${p => p.bg};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out .3s all;
    :hover{
        background-color: ${p => `${p.bg}aa`};
    }
`

const Link = styled(Text)`
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`