import React from 'react'
import styled from 'styled-components'
import DefaultChip from '../../styled-components/DefaultChip'
import Text from '../../styled-components/Text'
import alab from "../../assets/images/alab.png";
import Image from '../../styled-components/Image';
import DefaultButton from '../../styled-components/DefaultButton';
import Share from '../shared/Share';
import { useState } from 'react';
import EmailRequest from '../shared/EmailRequest';

const Options = (props) => {

    const { courses, teacher } = props

    const [openShare, setOpenShare] = useState(false)
    const [openEmail, setOpenEmail] = useState(false)

    const onCloseShare = () => setOpenShare(false)
    const onOpenShare = () => setOpenShare(true)
    const onCloseEmail = () => setOpenEmail(false)
    const onOpenEmail = () => setOpenEmail(true)

    if (!courses) {
        return <div/>
    }

    return (
        <OptionsContainer>
            <CardOption>
                <OptionCase>
                    <DefaultChip title="OPCIÓN A" color="#b31d15" />
                </OptionCase>
                <Text fontSize="36px" fontWeight="700">Cursado en</Text>
                <Image width={"300px"} src={alab} />
                <CenterText>
                    <Text fontFamily="Lato" fontSize="24px" fontWeight="400">
                        Podrás compartir a tus colegas {courses?.length - 1 > 1 && 'hasta'} {courses?.length - 1} {courses?.length - 1 === 1 ? 'curso' : 'cursos'} de impacto. Obteniendo la certificación de Acrópolis Lab {courses.length - 1 > 1 && 'por cada uno'}.
                    </Text>
                </CenterText>
                {/* <Text fontFamily="Lato" fontSize="16px" fontWeight="400">
                    *Tendrás tiempo de registrarte hasta el 30 de septiembre de 2022
                </Text> */}
                <Buttons>
                    <DefaultButton href="#form" color="primary" variant="contained">Registrarme</DefaultButton>
                    <DefaultButton onClick={onOpenShare} color="secondary" variant="contained">Invitar a cursar</DefaultButton>
                </Buttons>
                <Share open={openShare} onClose={onCloseShare}/>
            </CardOption>
            <CardOption>
                <OptionCase>
                    <DefaultChip title="OPCIÓN B" color="#b31d15" />
                </OptionCase>
                <Text fontSize="36px" fontWeight="700">Cursado en</Text>
                <CenterText>
                    <Text fontFamily="Lato" fontSize="32px" fontWeight="900">TU PLATAFORMA EDUCATIVA</Text>
                </CenterText>
                <CenterText>
                    <Text fontFamily="Lato" fontSize="24px" fontWeight="400">
                        ADEN hará entrega de {courses?.length - 1} {courses?.length - 1 === 1 ? 'curso' : 'cursos'} para que los utilices dentro de tu organización. Obtendrá la certificación de Acrópolis Lab {courses.length - 1 > 1 && 'por cada uno'}.
                    </Text>
                </CenterText>
                <Text fontFamily="Lato" fontSize="16px" fontWeight="400">
                    *ADEN no brindará soporte o seguimiento personalizado
                </Text>
                <Buttons>
                    <DefaultButton onClick={onOpenEmail} color="primary" variant="contained">Solicitar link de descarga</DefaultButton>
                </Buttons>
                <EmailRequest teacher={teacher} open={openEmail} onClose={onCloseEmail}/>
            </CardOption>
        </OptionsContainer>
    )
}

export default Options

const OptionsContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    gap: 36px;
    justify-content: center;
    @media (max-width: 768px){
        flex-direction: column;
        height: auto;
    }
`

const CardOption = styled.div`
    background: #222222;
    width: 568px;
    height: 597px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 768px){
        width: auto;
        height: auto;
        text-align: center;
        padding: 1.5rem 0;
    }
`

const OptionCase = styled.div`
    position: absolute;
    top: -12px;
    transform: scale(1.5);
`

const CenterText = styled.div`
    width: 80%;
    margin: 18px 0;
    text-align: center;
`

const Buttons = styled.div`
    display: flex;
    gap: 18px;
    margin-top: 36px;
`