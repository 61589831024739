import { useEffect } from "react"
import { useState } from "react"

const useCourses = (coursesParams) => {

    const [courses, setCourses] = useState(null)

    useEffect(() => {
        getCourses()
    }, [])

    const getCourses = async () => {
        try {
            const data = await fetch('https://repositorio-api.aden.org/api/v1/repo_aden/ondemand/courses/own_by_ondemand/73')
            const { response_data } = await data.json()
            let result = []
            response_data.forEach(course => {
                const isExist = coursesParams.some(c => c === course.id)
                if (isExist) {
                    result = [...result, course]
                }
            });
            setCourses(result)
        } catch (error) {
            setCourses(false)
        }
    }


    return { courses }
}

export default useCourses