import React from 'react'
import styled from 'styled-components'
import Text from '../../styled-components/Text'
import { Candle } from '../shared/Candle'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DefaultButton from '../../styled-components/DefaultButton'

const Banner = (props) => {

    const { teacher } = props

    const BASE_URL = window.location.origin;

    return (
        <Container teacher={`${BASE_URL}/static/${teacher.img}`}>
            <Information>
                <Text id="banner-title" fontSize="4rem" fontWeight="800">ADEN te invita a expandir tu conocimiento</Text>
                <Text fontSize="1rem" fontWeight="700">
                    ADEN te acompaña a realizar {teacher?.courses.length - 1 > 1 && 'hasta'} {teacher?.courses.length - 1} {teacher?.courses.length - 1 === 1 ? 'curso' : 'cursos'} de impacto. {teacher?.courses.length - 1 === 1 ? 'Cuando' : 'Por cada uno que'} completes obtendrás una certificación AcrópolisLAB. ¡Regístrate y comienza a cursar ahora!
                </Text>
                {/* <Text fontSize=".9rem" fontWeight="400">*Tendrás tiempo de registrarte hasta el 30 de septiembre de 2022</Text> */}
                <Buttons>
                    <DefaultButton href="#form" color='primary' variant='contained' endIcon={<ArrowForwardIcon />}>Registrarme</DefaultButton>
                    <DefaultButton href="#courses" color='secondary' variant='contained'>Conoce los cursos de impacto</DefaultButton>
                </Buttons>
            </Information>
            {teacher.hash === 'ac' || teacher.hash === 'ca' && (
                <PositionCandle>
                    <Candle />
                </PositionCandle>
            )}
        </Container>
    )
}

export default Banner

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #00000000;
    background-image: url(${p => p.teacher});
    ${p => p.teacher && `
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 100px;
        width: calc(100% - 200px);
    `}
    @media (max-width: 768px){
        text-align: center;
    }
`

const PositionCandle = styled.div`
    height: 250px;
    position: absolute;
    bottom: 150px;
    right: 30%;
    .candle{
        transform: scale(2);
    }
    /*  */
    @media (max-width: 768px){
        display: flex;
        height: auto;
        justify-content: center;
        align-items: center;
        bottom: 0;
        top: 150px;
        left: 0;
        right: 0;
        z-index: 1;
        /* display: none; */
        width: 100%;
        height: 100px;
        filter: opacity(.2);
        .candle{
            transform: scale(.8);
        }
    }
`
//  te invitó a expandir tu conocimiento para impactar nuestro entorno, url
// wp


const Information = styled.div`
    display: flex; 
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    width: 60%;
    height: 100%;
    margin-top: 2rem;
    z-index: 9999;
    @media (max-width: 768px){
        width: 100%;
        #banner-title{
            font-size: 36px;
            /* margin-top: 100px; */
            /* margin-bottom: 180px; */
        }
    }
`

const Buttons = styled.div`
    display: flex;
    gap: 16px;
    @media (max-width: 768px){
        width: 100%;
        flex-direction: column;
    }
`

const Position = styled.div`
    position: absolute;
    top: ${p => p.top};
    bottom: ${p => p.bottom};
    left: ${p => p.left};
    right: ${p => p.right};
`