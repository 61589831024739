// // import { TextField } from "@material-ui/core";
// import { Field } from "formik";
// import styled from "styled-components";


import React from 'react';
import styled from 'styled-components';

import { ErrorMessage, Field } from 'formik';
import { TextField } from '@mui/material';

const DefaultField = (props) => {
    const { name, type, label, variant = "outlined" } = props;

    // RETURN
    return (
        <Field
            as={TextFieldFake}
            name={name}
            helperText={<ErrorMessage name={name} component={CustomError} />}
            type={type}
            label={label}
            variant={variant}
            color="primary"
            size="small"
            fullWidth
            {...props}
        />
    );
};

export default DefaultField;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    font-family: Lato;
    color: #D24335;
`;

const TextFieldFake = styled(TextField)`
    fieldset{
        border-color: #8A8A8A;
    }
    label{
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #8A8A8A;
    }
    .MuiInputBase-input{
        color: white;
    }
    svg{
        color: white;
    }
`