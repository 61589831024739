import React from 'react'
import styled from 'styled-components'
import Image from '../../styled-components/Image'
import logo from '../../assets/images/aden.png'
import DefaultRoundedButton from '../../styled-components/DefaultRoundedButton'
import ShareIcon from '@mui/icons-material/Share';
import Share from '../shared/Share'
import { useState } from 'react'

const Navbar = () => {

    const [open, setOpen] = useState(false)

    const onClose = () => setOpen(false)

    return (
        <NavContainer>
            <Image src={logo} />
            <DefaultRoundedButton id={"navbtn"} title="COMPARTIR" icon={<ShareIcon/>} onClick={() => setOpen(true)}/>
            <Share open={open} onClose={onClose}/>
        </NavContainer>
    )
}

export default Navbar

const NavContainer = styled.nav`
    width: calc(100% - 156px);
    padding: 48px 78px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: space-between;
    background-color: #00000000;
    z-index: 9;
    /* background-color: red; */
    @media (max-width: 768px){
        width: calc(100% - 24px);
        padding: 12px;
        height: 40px;
        align-items: center;
        img{
            width: 200px;
            height: 30px;
        }
        #navbtn{
            height: 30px;
            span{
                display: none;
            }
        }
    }
`