import { useEffect } from 'react';
import styled from 'styled-components';
import ringer from '../../assets/audio/sound.mpeg'
// import DefaultRoundedButton from '../../styled-components/DefaultRoundedButton';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useState } from 'react';
import { useRef } from 'react';
import { IconButton } from '@mui/material';

const Sound = () => {

    // let audio = new Audio(ringer);
    const [play, setPlay] = useState(true)
    const myRef = useRef();

    useEffect(() => {
        // audio.loop = play;
        play && myRef.current.play();
    }, [])

    const handlePlay = () => {
        setPlay(true)
        myRef.current.play();
    }

    const handlePause = () => {
        setPlay(false)
        myRef.current.pause();
        // audio = false;
    }

    return (
        <ButtonPlay>
            <Audio
                ref={myRef}
                src={ringer}
            />
            {play && <IconButton onClick={handlePause}><PauseIcon /></IconButton>}
            {!play && <IconButton onClick={handlePlay}><PlayArrowIcon /></IconButton>}
            {/* <button onClick={() => (audio.loop = false)}>Pause</button> */}
        </ButtonPlay>
    );
};

export default Sound

const ButtonPlay = styled.div`
    position: fixed;
    bottom: 90px;
    right: 30px;
    @media (max-width: 768px){
        display: none;
    }
`

const Audio = styled.audio`
    display: none;
`