import React from 'react'
import styled from 'styled-components'

const Footer = () => {
    return (
        <FooterContainer>
            Todos los derechos reservados | © ADEN {new Date().getFullYear()}
        </FooterContainer>
    )
}

export default Footer

const FooterContainer = styled.footer`
    width: 100%;
    height: 84px;
    background-color: #000;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
`