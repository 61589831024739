import styled from 'styled-components';
import Banner from './components/banner/Banner';
import Courses from './components/courses/Courses';
import Navbar from './components/navbar/Navbar';
import MaterialThemeProvider from './theme/Material';
import bg from './assets/images/bg.png'
import Form from './components/form/Form';
import Footer from './components/footer/Footer';
import Sound from './components/shared/Sound';
import { useState } from 'react';
import Options from './components/options/Options';
import SnackbarProvider from 'react-simple-snackbar'
import useTeacher from './hooks/useTeacher';
import useParams from './hooks/useParams';

function App() {

  const { all } = useParams()
  const [ teacherPage ] = all

  const { teacher } = useTeacher(teacherPage)

  const [courses, setCourses] = useState(null)

  const handleCourses = (courses) => setCourses(courses)

  if (teacher === null) {
      return false
  }

  return (
    <MaterialThemeProvider>
      <SnackbarProvider>
        <Sound />
        <Page bg={bg}>
          <Navbar />
          <Banner teacher={teacher}/>
          <Courses teacher={teacher} handleCourses={handleCourses} />
          <Options courses={courses} teacher={teacher}/>
          <Form courses={courses} teacher={teacher}/>
          <Footer />
        </Page>
      </SnackbarProvider>
    </MaterialThemeProvider>
  );
}

export default App;

const Page = styled.div`
  background-image: url(${p => p.bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 90%;
  background-attachment: fixed;
  padding: 0 100px;
  @media (max-width: 768px){
    padding: 0 12px;
  }
`
