import styled from "styled-components"
import React from 'react'

const DefaultChip = (props) => {

    const { title, color } = props

  return (
    <DefaultChipStyled color={color}>{title}</DefaultChipStyled>
  )
}

export default DefaultChip

const DefaultChipStyled = styled.div`
    content: ${p => p.title};
    width: fit-content;
    padding: 5px;
    background-color: ${p => p.color};
    color: white;
    font-size: 12px;
    border-radius: 5px;
    font-family: Lato;
`
