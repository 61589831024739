import { Button } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const DefaultButton = (props) => {
    return <ButtonFake {...props} />
}

export default DefaultButton

const ButtonFake = styled(Button)`
    padding: 12px 24px  !important;
    border-radius: 10px !important;
`