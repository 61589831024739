import { useEffect } from "react"
import { useState } from "react"

const useTeacher = (id) => {

    const [teacher, setTeacher] = useState(null)

    useEffect(() => {
        getTeacher()
    }, [])

    const getTeacher = async () => {
        try {
            const data = await fetch(window.location.origin + '/data/teachers.json')
            const { teachers } = await data.json()
            const result = teachers.find(teacher => teacher.hash === id)
            setTeacher(result)
        } catch (error) {
            setTeacher(false)
        }
    }


    return { teacher }
}

export default useTeacher