import React from 'react'
import styled from 'styled-components'

const DefaultRoundedButton = (props) => {

    const { icon, title = false, onClick, id } = props

    return (
        <RoundedButton id={id} onClick={onClick}>
            {icon}
            <span>{title}</span>
        </RoundedButton>
    )
}

export default DefaultRoundedButton

const RoundedButton = styled.div`
    cursor: pointer;
    border: 2px solid #aaa;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 0 12px;
    border-radius: 20px;
    text-transform: uppercase;
    transition: ease-in-out .1s all;
    span{
        font-family: Lato;
        color: #aaa;
    }
    svg{
        fill: #aaa !important;
        font-size: 20px;
    }
    :hover{
        color: #ccc;
        border: 2px solid #ccc;
        svg{
            fill: #ccc !important;
        }
    }
`