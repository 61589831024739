import { useEffect } from "react";
import { useState } from "react";
import { getCompanies, getCountries } from "../api/sis-api";

const useUtils = () => {
    // STATES
    const [countries, setCountries] = useState(null);
    const [companies, setCompanies] = useState(null);

    // EEFFECT
    useEffect(() => {
        countries === null && getCountry();
        companies === null && getCompany();
    }, []);

    // FUNCTIONS
    const getCountry = async () => {
        const req = await getCountries();

        if (!req.error) {
            setCountries(req);
        } else {
            setCountries([]);
            console.error("No se pudieron cargar los países");
        }
    };

    const getCompany = async () => {
        const req = await getCompanies();

        if(!req.error) {
            setCompanies(req);
        } else {
            setCountries([]);
            console.error("No se pudieron cargar las empresas");
        }
    }

    return { countries, companies };
};

export default useUtils;
