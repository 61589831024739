import { Button, Dialog, IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Text from "../../styled-components/Text";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { Formik, Form as FormFormik } from "formik";
import { requestScorm } from "../../schemas/email";
import DefaultField from "./DefaultField";
import { sendMail } from "../../api/sis-api";
import { useSnackbar } from "react-simple-snackbar";
import CheckIcon from "@mui/icons-material/Check";
import useSearchEmailTemplate from "../../hooks/useSearchEmailTemplate";

const EmailRequest = (props) => {
    const { open = true, onClose, teacher } = props;

    const { emailTemplate } = useSearchEmailTemplate(teacher.hash);

    const initialValues = {
        name: "",
        business: "",
        email: "",
    };

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [openSnackbar] = useSnackbar();

    const handleSubmit = async (values) => {
        setLoading(true);
        const bodyUser = {
            from_email: "adencursos@aden.org",
            to_emails: [values.email],
            subject: "¡Es hora de empezar a cursar! | ADEN te acompaña",
            html_content: emailTemplate,
        };
        const bodyAunt = {
            from_email: "adencursos@aden.org",
            to_emails: ["ifederici@aden.org"],
            subject: `${values.name} solicitó los Paquetes Scorm`,
            html_content: `<div>
            <h2>Datos del Formulario</h2>
            <h3>El usuario: <b>${values.name}</b></h1>
            <h3>Empresa: <b>${values.business}</b></h3>
            <h3>Correo: <b>${values.email}</b></h3>
        </div>`,
        };
        const requestUser = await sendMail(bodyUser);
        const requestAunt = await sendMail(bodyAunt);
        setLoading(false);
        setPage(1);
        openSnackbar("Se ha enviado la solicitud correctamente");
    };

    return (
        <DialogWrapper open={open} onClose={onClose}>
            {page === 0 && (
                <Content>
                    <Close>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Close>

                    <Text fontSize="24px" fontWeight="900" fontFamily="Lato">
                        Descarga SCORM
                    </Text>
                    <Center>
                        <Text
                            fontSize="20px"
                            fontWeight="400"
                            fontFamily="Lato"
                        >
                            Completa la solicitud y en breve nos comunicaremos
                            contigo
                        </Text>
                    </Center>

                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={requestScorm}
                    >
                        {() => (
                            <FormF>
                                {/** EMAIL */}
                                <DefaultField
                                    name="name"
                                    type="text"
                                    label="Nombre completo"
                                    variant="outlined"
                                    required
                                />
                                <DefaultField
                                    name="email"
                                    type="text"
                                    label="Correo electrónico"
                                    variant="outlined"
                                    required
                                />
                                <DefaultField
                                    fullWidth
                                    name="business"
                                    type="text"
                                    label="Empresa"
                                    variant="outlined"
                                    required
                                />
                                <Button
                                    disabled={loading}
                                    type="submit"
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                >
                                    {!loading
                                        ? "Enviar solicitud"
                                        : "Enviando solicitud"}
                                </Button>
                            </FormF>
                        )}
                    </Formik>
                </Content>
            )}
            {page === 1 && (
                <Content>
                    <Circle>
                        <CheckIcon />
                    </Circle>
                    <Text fontSize="18px" fontWeight="900" fontFamily="Lato">
                        ¡Se há enviado la solicitud correctamente!
                    </Text>
                    <Button variant="contained" onClick={() => setPage(0)}>
                        Volver
                    </Button>
                </Content>
            )}
        </DialogWrapper>
    );
};

export default EmailRequest;

const DialogWrapper = styled(Dialog)`
    .MuiDialog-paper {
        border-radius: 20px;
        background-color: #222;
        min-width: 432px;
        height: 380px;
        padding: 24px;
        color: #fff;
        @media (max-width: 768px) {
            min-width: auto;
        }
    }
    /* position: relative; */
`;

const FormF = styled(FormFormik)`
    display: flex;
    justify-content: center;
    min-width: 60%;
    flex-direction: column;
    gap: 20px;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    position: relative;
`;

const Close = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const Center = styled.div`
    width: 90%;
    text-align: center;
`;

const Circle = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #3bd39c;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        fill: white;
        font-size: 64px;
    }
`;
