import { useEffect, useState } from "react";
import {
    emailRegister_LUIS_ESKEMBRE,
    emailRegister_ANDRES_CULASSO,
    emailRegister_LILIANA_MARTINEZ,
    email_ANDRES_CULASSO,
    email_LILIANA_MARTINEZ,
    email_LUIS_ESKEMBRE,
    email_LAURA_NAJAR,
} from "../utils/emailTemplate";

const useSearchEmailTemplate = (hash) => {
    const [emailRegister, setEmailRegister] = useState();
    const [emailTemplate, setEmailTemplate] = useState();

    const hashArray = [
        {
            key: "ac",
            template: emailRegister_ANDRES_CULASSO,
            emailTemplate: email_ANDRES_CULASSO,
        },
        {
            key: "ca",
            template: emailRegister_ANDRES_CULASSO,
            emailTemplate: email_ANDRES_CULASSO,
        },
        {
            key: "lm",
            template: emailRegister_LILIANA_MARTINEZ,
            emailTemplate: email_LILIANA_MARTINEZ,
        },
        {
            key: "ml",
            template: emailRegister_LILIANA_MARTINEZ,
            emailTemplate: email_LILIANA_MARTINEZ,
        },
        {
            key: "le",
            template: emailRegister_LUIS_ESKEMBRE,
            emailTemplate: email_LUIS_ESKEMBRE,
        },
        {
            key: "ln",
            template: emailRegister_LILIANA_MARTINEZ,
            emailTemplate: email_LAURA_NAJAR,
        },
    ];

    useEffect(() => {
        !!hash && handleEmailRegister(hash);
    }, [hash]);

    const handleEmailRegister = (emailHash) => {
        hashArray.forEach((el) => {
            el.key === emailHash && setEmailRegister(el.template);
            el.key === emailHash && setEmailTemplate(el.emailTemplate);
        });
    };
    return { emailRegister, emailTemplate };
};

export default useSearchEmailTemplate;
