import React from 'react'
import styled from 'styled-components'
import DefaultChip from '../../styled-components/DefaultChip'
import Text from '../../styled-components/Text'

const CardSlider = (props) => {

    const { image, name, chip, description } = props

    return (
        <CardContainer bg={image}>
            <Gradient/>
            <div id={"id"}>
                <DefaultChip  title="ACTIVIDAD + CERTIFICADO" color="#b31d15"/>
            </div>
            <Data>
                <Text id="title-card" fontFamily="Lato" fontSize="24px" fontWeight="900">{name}</Text>
                <Scroll>
                    <Text id="desc-card" fontFamily="Lato" fontSize="16px" fontWeight="400">{description}</Text>
                </Scroll>
            </Data>
        </CardContainer>
    )

}

export default CardSlider


const CardContainer = styled.div`
    width: 520px;
    min-width: 520px;
    height: 350px;
    border-radius: 20px;
    background-image: url(${p => p.bg});
    background-size: cover; 
    background-position: left top;
    background-size: 100%;
    position: relative;
    #id{
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
    @media (max-width: 768px){
        width: 100%;
        min-width: auto;
        display: flex;
        justify-content: center;
        #id{
            margin-top: 16px;
            position: static;
            font-size: 10px;
        }
    }
`

const Gradient = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 100%;
    background: rgb(17,17,17);
    background: linear-gradient(0deg, rgba(17,17,17,0.95) 0%, rgba(44,44,44,0) 80%);
    @media (max-width: 768px){
        background: linear-gradient(0deg, rgba(17,17,17,0.90) 60%, rgba(44,44,44,0) 100%);
    } 
` 

const Data = styled.div`
    width: calc(100% - 32px);
    height: 30%;
    padding: 0 16px;
    position: absolute;
    bottom: 0;
    @media (max-width: 768px){
        height: 50%;
        #title-card{
            font-size: 18px;
            margin-bottom: 16px;
        }
        #desc-card{
            font-size: 14px;
        }
    }
`

const Scroll = styled.div`
    max-height: 60px;
    overflow-y: auto;
    @media (max-width: 768px){
        max-height: 120px;
    }
`